/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-11-28 16:19:08.

export interface LoginRequest {
    rememberMe: boolean;
    refresh_token: string | null;
    clientData: string | null;
}

export interface LoginResponse {
    access_token: string;
    refresh_token: string | null;
    expires_in: number;
}

export interface RevokeRequest {
    token: string;
    tokenTypeHint: string | null;
    clientData: string;
}

export interface SignatureRequest {
    credentialID: string;
    hash: string[];
    hashAlgo: string | null;
    signAlgo: string;
    signAlgoParams: string | null;
    clientData: string | null;
    SAD: string;
}

export interface SignatureResponse {
    signatures: string[];
}

export interface TimestampRequest {
    hash: string;
    hashAlgo: string;
    nonce: string | null;
    clientData: string | null;
}

export interface TimestampResponse {
    timestamp: any;
}

export interface CredentialAuthorizeRequest {
    credentialID: string;
    numSignatures: number;
    hash: string[] | null;
    description: string | null;
    clientData: string | null;
    pin: string | null;
    otp: string | null;
}

export interface CredentialAuthorizeResponse {
    expiresIn: number;
    SAD: string;
}

export interface CredentialExtendRequest {
    credentialID: string;
    clientData: string | null;
    SAD: string;
}

export interface CredentialExtendResponse {
    SAD: string;
}

export interface CredentialInfoRequest {
    credentialID: string;
    certificates: CertificateForm;
    certInfo: boolean;
    authInfo: boolean;
    lang: string | null;
    clientData: string | null;
}

export interface CredentialInfoResponse {
    key: KeyResponse;
    cert: CertResponse;
    description: string | null;
    authMode: AuthMode | null;
    multisign: boolean | null;
    lang: string | null;
    PIN: PinResponse | null;
    OTP: OtpResponse | null;
    SCAL: Scal | null;
}

export interface CredentialListRequest {
    userId: string | null;
    maxResults: number;
    pageToken: string | null;
    clientData: string | null;
}

export interface CredentialListResponse {
    credentialIDs: string[];
    nextPageToken: string | null;
}

export interface CredentialSendOtpRequest {
    credentialID: string;
    clientData: string | null;
}

export interface FrontInfoResponse {
    consentFrontInfo: ConsentFrontInfo;
    oauth2Servers: Oauth2ServersInfo[];
    oauth2ServersGroups: Record<string, Oauth2ServersInfo[]>;
}

export interface InfoRequest {
    lang: string | null;
}

export interface InfoResponse {
    specs: string;
    name: string;
    logo: string;
    region: string;
    lang: string;
    description: string;
    authType: AuthenticationTypeEnum[];
    methods: CscMethod[];
    oauth2: string | null;
}

export interface KeyResponse {
    status: KeyStatus;
    algo: string[];
    len: number;
    curve: string | null;
}

export interface CertResponse {
    status: CertStatus;
    certificates: string[] | null;
    issuerDN: string | null;
    serialNumber: string | null;
    subjectDN: string | null;
    validFrom: string | null;
    validTo: string | null;
}

export interface PinResponse {
    presence: Presence;
    format: CodeFormat | null;
    label: string | null;
    description: string | null;
}

export interface OtpResponse {
    presence: Presence;
    type: OtpType | null;
    format: CodeFormat | null;
    label: string | null;
    description: string | null;
    provider: string | null;
    ID: string | null;
}

export interface ConsentFrontInfo {
    gcsLink: string;
    gcsLabel: string;
    gcsLinkLabel: string;
    personalDataLabel: string;
    personalDataDetailLabel: string;
    personalDataLinkLabel: string;
    grpdDetail: string;
}

export interface Oauth2ServersInfo {
    id: string;
    name: string;
    description: string;
    newUser: boolean;
    logo: string | null;
}

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export class RestApplicationClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /csc/v0/auth/login
     * Java method: com.worldline.edoc.csc.web.AuthWebservice.login
     */
    login(request: LoginRequest, options?: O): RestResponse<LoginResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/auth/login`, data: request, options: options });
    }

    /**
     * HTTP POST /csc/v0/auth/revoke
     * Java method: com.worldline.edoc.csc.web.AuthWebservice.revoke
     */
    revoke(request: RevokeRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/auth/revoke`, data: request, options: options });
    }

    /**
     * HTTP POST /csc/v0/credentials/authorize
     * Java method: com.worldline.edoc.csc.web.CredentialsWebservice.authorize
     */
    authorize(request: CredentialAuthorizeRequest, options?: O): RestResponse<CredentialAuthorizeResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/credentials/authorize`, data: request, options: options });
    }

    /**
     * HTTP POST /csc/v0/credentials/extendTransaction
     * Java method: com.worldline.edoc.csc.web.CredentialsWebservice.extendTransaction
     */
    extendTransaction(request: CredentialExtendRequest, options?: O): RestResponse<CredentialExtendResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/credentials/extendTransaction`, data: request, options: options });
    }

    /**
     * HTTP POST /csc/v0/credentials/info
     * Java method: com.worldline.edoc.csc.web.CredentialsWebservice.info
     */
    info$POST$csc_v0_credentials_info(request: CredentialInfoRequest, options?: O): RestResponse<CredentialInfoResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/credentials/info`, data: request, options: options });
    }

    /**
     * HTTP POST /csc/v0/credentials/list
     * Java method: com.worldline.edoc.csc.web.CredentialsWebservice.list
     */
    list(request: CredentialListRequest | null, options?: O): RestResponse<CredentialListResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/credentials/list`, data: request, options: options });
    }

    /**
     * HTTP POST /csc/v0/credentials/sendOTP
     * Java method: com.worldline.edoc.csc.web.CredentialsWebservice.sendOtp
     */
    sendOtp(request: CredentialSendOtpRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/credentials/sendOTP`, data: request, options: options });
    }

    /**
     * HTTP GET /csc/v0/front
     * Java method: com.worldline.edoc.csc.web.FrontWebService.info
     */
    info$GET$csc_v0_front(queryParams: { lang: string; tenant: string; }, options?: O): RestResponse<FrontInfoResponse> {
        return this.httpClient.request({ method: 'GET', url: uriEncoding`csc/v0/front`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /csc/v0/info
     * Java method: com.worldline.edoc.csc.web.InfoWebservice.info
     */
    info$POST$csc_v0_info(request: InfoRequest, options?: O): RestResponse<InfoResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/info`, data: request, options: options });
    }

    /**
     * HTTP GET /csc/v0/oauth2/consent
     * Java method: com.worldline.edoc.csc.web.OauthWebService.consent
     */
    consent(queryParams: { redirect_uri?: string; consent_id: string; response_type: string; client_id: string; lang?: string; consent_cgs: boolean; consent_personal_data: boolean; new_user?: boolean; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'GET', url: uriEncoding`csc/v0/oauth2/consent`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /csc/v0/oauth2/redirect
     * Java method: com.worldline.edoc.csc.web.OauthWebService.redirect
     */
    redirect(queryParams: { code: string; state: string; error: string; error_description: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'GET', url: uriEncoding`csc/v0/oauth2/redirect`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /csc/v0/oauth2/redirect-enrollment
     * Java method: com.worldline.edoc.csc.web.OauthWebService.redirectEnrollment
     */
    redirectEnrollment(queryParams: { consent_id: string; response_type: string; lang?: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'GET', url: uriEncoding`csc/v0/oauth2/redirect-enrollment`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /csc/v0/oauth2/refuse
     * Java method: com.worldline.edoc.csc.web.OauthWebService.refuse
     */
    refuse(queryParams: { redirect_uri: string; consent_id?: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'GET', url: uriEncoding`csc/v0/oauth2/refuse`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /csc/v0/oauth2/select-sso
     * Java method: com.worldline.edoc.csc.web.OauthWebService.selectSso
     */
    selectSso(queryParams: { redirect_uri?: string; consent_id: string; response_type: string; client_id: string; sso_id: string; lang?: string; new_user?: boolean; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'GET', url: uriEncoding`csc/v0/oauth2/select-sso`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /csc/v0/oauth2/validate-evidence-info
     * Java method: com.worldline.edoc.csc.web.OauthWebService.validateEvidenceInfo
     */
    validateEvidenceInfo(queryParams: { consent_id: string; validate_info: boolean; phone_number: string; }, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/oauth2/validate-evidence-info`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /csc/v0/oauth2/validate-info/{encoded_data}
     * Java method: com.worldline.edoc.csc.web.OauthWebService.validate
     */
    validate(encoded_data: string, queryParams: { validate_info: boolean; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: 'GET', url: uriEncoding`csc/v0/oauth2/validate-info/${encoded_data}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /csc/v0/signatures/signHash
     * Java method: com.worldline.edoc.csc.web.SignatureWebservice.sign
     */
    sign(request: SignatureRequest, options?: O): RestResponse<SignatureResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/signatures/signHash`, data: request, options: options });
    }

    /**
     * HTTP POST /csc/v0/signatures/timestamp
     * Java method: com.worldline.edoc.csc.web.SignatureWebservice.timestamp
     */
    timestamp(request: TimestampRequest, options?: O): RestResponse<TimestampResponse> {
        return this.httpClient.request({ method: 'POST', url: uriEncoding`csc/v0/signatures/timestamp`, data: request, options: options });
    }
}

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export enum CertificateForm {
    NONE = 'none',
    SINGLE = 'single',
    CHAIN = 'chain',
}

export enum AuthMode {
    IMPLICIT = 'implicit',
    EXPLICIT = 'explicit',
    OAUTH2CODE = 'oauth2code',
    OAUTH2TOKEN = 'oauth2token',
}

export enum Scal {
    SCAL1 = '1',
    SCAL2 = '2',
}

export enum AuthenticationTypeEnum {
    EXTERNAL = 'external',
    TLS = 'TLS',
    BASIC = 'basic',
    DIGEST = 'digest',
    OAUTH2_CODE = 'oauth2code',
    OAUTH2_IMPLICIT = 'oauth2implicit',
    OAUTH2_CLIENT = 'oauth2client',
}

export enum CscMethod {
    REVOKE = 'auth/revoke',
    OAUTH_TOKEN = 'oauth2/token',
    CREDENTIAL_LIST = 'credentials/list',
    CREDENTIAL_INFO = 'credentials/info',
    CREDENTIAL_AUTHORIZE = 'credentials/authorize',
    CREDENTIAL_OTP = 'credentials/sendOTP',
    SIGNATURE_HASH = 'signatures/signHash',
}

export enum KeyStatus {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
}

export enum CertStatus {
    VALID = 'valid',
    EXPIRED = 'expired',
    REVOKED = 'revoked',
    SUSPENDED = 'suspended',
}

export enum Presence {
    TRUE = 'true',
    FALSE = 'false',
}

export enum CodeFormat {
    A = 'A',
    N = 'N',
}

export enum OtpType {
    OFFLINE = 'offline',
    ONLINE = 'online',
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = '';
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AxiosClientExtension' extension

import axios from 'axios';
import * as Axios from 'axios';

declare module 'axios' {
    export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
        data: R;
    }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {

    constructor(private axios: Axios.AxiosInstance) {
    }

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: Axios.AxiosRequestConfig; }): RestResponse<R> {
        function assign(target: any, source?: any) {
            if (source != undefined) {
                for (const key in source) {
                    if (source.hasOwnProperty(key)) {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }

        const config: Axios.AxiosRequestConfig = {};
        config.method = requestConfig.method as typeof config.method;  // `string` in axios 0.16.0, `Method` in axios 0.19.0
        config.url = requestConfig.url;
        config.params = requestConfig.queryParams;
        config.data = requestConfig.data;
        assign(config, requestConfig.options);
        const copyFn = requestConfig.copyFn;

        const axiosResponse = this.axios.request(config);
        return axiosResponse.then(axiosResponse => {
            if (copyFn && axiosResponse.data) {
                (axiosResponse as any).originalData = axiosResponse.data;
                axiosResponse.data = copyFn(axiosResponse.data);
            }
            return axiosResponse;
        });
    }
}

export class AxiosRestApplicationClient extends RestApplicationClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
